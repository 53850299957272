import React from "react"

import Header from "../components/header"
import Footer from "../components/footer"

import SEO from "../components/seo"

import Schlossausstattung1 from "../components/schlossausstattung1"
import Schlossausstattung2 from "../components/schlossausstattung2"
import Schlossausstattung3 from "../components/schlossausstattung3"
import Schlossausstattung4 from "../components/schlossausstattung4"
import Schlossausstattung5 from "../components/schlossausstattung5"
import Schlossausstattung6 from "../components/schlossausstattung6"
import Schlossausstattung7 from "../components/schlossausstattung7"

import "./index.css"

export default function SchlossinterieurPage() {
  return (
    <>
      <SEO
        description="restaura ratisbona, Ihr Team für Restaurierungen. Schlossausstattunge"
        title="restaura ratisbona, Ihr Team für Restaurierungen. Schlossausstattunge"
        lang="de"
      />
      <Header />
      <section>
        <h1 className="indexHeadline">Schlossinterieur</h1>
        <h2 className="referenceH2">
        Ehrenburg-Coburg, Restaurierung eines Sofas, vier Armlehnsessel, sechs Lehnstühle und ein Salontisch (2020)
        </h2>
        <h3 className="referenceH3">
          Konservierung, Reinigung, Kreidegrundergänzung, Goldretusche in Tratteggio-Technik, Retusche der Weißfassung
        </h3>
        <div className="referencePageDiv">
          <div className="referenceImages">
            <label htmlFor="Schlossausstattung1">
              <Schlossausstattung1 id="Schlossausstattung1" />
              Armlehnsessel Vorzustand
              <br />
              (Foto: Astrid Mendes)
            </label>
            <label htmlFor="Schlossausstattung2">
              <Schlossausstattung2 id="Schlossausstattung2" />
              Armlehnsessel nach der Kittung
              <br />
              (Foto: Astrid Mendes)
            </label>
            <label htmlFor="Schlossausstattung3">
              <Schlossausstattung3 id="Schlossausstattung3" />
              Sofa nach der Tratteggio-Retusche
              <br />
              (Foto: Astrid Mendes)
            </label>
          </div>
          <div className="referenceTextDiv">
            <h4 className="referenceH4">Geschichte und Bedeutung</h4>
            <p className="referenceText">
            Das Mobiliar wurde 1811 in der Möbelwerkstatt von Georg Jacob und des Francois-Honoré-George Jacob-Desmalter bestellt. Der florale Bezugsstoff stammt von 1884. Dieses Entstehungsdatum nimmt man auch für den Salontisch an. Die Fassung ist weiß mit vergoldeten Profilen.
            </p>

            <h4 className="referenceH4">Erhaltungszustand / Befundsituation</h4>
            <p className="referenceText">
            Es haben sich an den hervorstehenden vergoldeten Möbelkanten und Profilleisten des Sofas und der Stühle zahlreiche Fehlstellen gebildet. Die Farbfassung weist Gebrauchsspuren vorwiegend im unteren Bereich der Stuhlbeine auf. Zahlreiche Reparaturen in Bronze, die nun nachgedunkelt sind wirken sich störend auf das Erscheinungsbild aus.
            </p>

            <h4 className="referenceH4">Restauratorische Zielstellung</h4>
            <p className="referenceText">
            Bestandserhaltung, Teilabnahme von Bronzierungen und rand- und formgenaue Fehstellenergänzung mit Kreidegrund. Die Retusche sollte in Tratteggio-Technik, im Bedarfsfall mit Pudergoldreflexen erfolgen.
            </p>

            <h4 className="referenceH4">Maßnahmen</h4>
            <p className="referenceText">
            Besonderes Augenmerk wurde auf die exakte Kittung der Möbelkanten gelegt. Es zeigte sich, dass durch die Tratteggio-Retusche der unterschiedliche Alterungsgrad des Originals aufgegriffen werden kann und somit eine gute Integration der Fehlstellen erzielt wird. Durch die Abnahme der Bronzierung wurde die Ästhetik wesentlich verbessert.
            </p>

            <h4 className="referenceH4">Fachbehörden / Auftraggeber</h4>
            <p className="referenceText">
            BSV-Möbelrestaurierung: Fachrestaurator Bernhard Mintrop. Werkstattleitung Dr. Heinrich Piening
            </p>
          </div>
        </div>
        <h2 className="referenceH2" id="schloss2">
          Bilderrahmen des Schlosses Alteglofsheim
        </h2>
        <h3 className="referenceH3">
          Konservierung mit Hausenblasenleim, Reinigung der Goldoberfläche,
          Fehlstellenergänzung mit Kreidegrund, partielle Ergänzung der
          Gittergravur, Pudergoldretusche der Fehlstellen, Blattgoldergänzung
          auf holzbildhauerischen Ergänzungen
        </h3>
        <div className="referencePageDiv">
          <div className="referenceImages">
            <label htmlFor="Schlossausstattung4">
              <Schlossausstattung4 id="Schlossausstattung4" />
              Endzustand der Rahmen über den seitlichen Kaminen. Asamsaal,
              Schloss Alteglofsheim
              <br />
              (Foto: Astrid Mendes)
            </label>
            <label htmlFor="Schlossausstattung5">
              <Schlossausstattung5 id="Schlossausstattung5" />
              Vorzustand
              <br />
              (Foto: Astrid Mendes)
            </label>
            <label htmlFor="Schlossausstattung6">
              <Schlossausstattung6 id="Schlossausstattung6" />
              Zwischenzustand
              <br />
              (Foto: Astrid Mendes)
            </label>
            <label htmlFor="Schlossausstattung7">
              <Schlossausstattung7 id="Schlossausstattung7" />
              Endzustand
              <br />
              (Foto: Astrid Mendes)
            </label>
          </div>
          <div className="referenceTextDiv">
            <h4 className="referenceH4">Geschichte und Bedeutung</h4>
            <p className="referenceText">
              Gegenstand der Restaurierung waren zwei Rahmen von 1731 mit den
              Maßen 245 x 183 cm, die jeweils ein Gemälde mit Jagdszenen über
              den Kaminen des Asamsaals zieren.
            </p>

            <h4 className="referenceH4">Erhaltungszustand / Befundsituation</h4>
            <p className="referenceText">
              Die ehemalige Vergoldung an den Bilderrahmen war stark
              verschmutzt. Die Oberfläche trug zudem einen partiellen
              Grauschleier, deshalb war ehemalige Goldcharakter war nicht mehr
              wahrzunehmen. Der graue Belag sollte in einem Fachlabor
              mikrobiologisch untersucht werden. Darüber hinaus haben sich
              hochstehende Kreidegrundschichten gebildet, die bereits zu
              Fehlstellen führten. Einige Verluste am Schnitzwerk waren zu
              verzeichnen.
            </p>

            <h4 className="referenceH4">Restauratorische Zielstellung</h4>
            <p className="referenceText">
              Die Vergrauung auf der Goldoberfläche war auf Mikroorganismen
              zurückzuführen. Der Belag sollte mit dem empfohlenen Mittel des
              Labors abgenommen werden. Fehlende Ornamente am Schnitzwerk, waren
              holzbildhauerisch zu ergänzen und entsprechend dem Original zu
              vergolden. Um den ästhetischen Eindruck verbessern sind die
              fehlenden Gittergravuren und Fehlstellen mit Pudergolderetusche zu
              ergänzen.
            </p>

            <h4 className="referenceH4">Maßnahmen</h4>
            <p className="referenceText">
              Die hochstehenden Goldschollen wurden mit Hausenblasenleim
              gefestigt und die Vergrauung auf der Oberfläche entfernt. Nach der
              Fehlstellenkittung wurde das partiell fehlende Gittermuster
              graviert. Die holzbilderhauerische Ergänzung erhielt nach der
              Kreidegrundgrundierung (vgl. Abbildung) eine Blattvergoldung. Für
              die Imitation der gealterten, relativ matten Vergoldung eignete
              sich eine Retusche aus Pudergold mit 24 Karat.
            </p>

            <h4 className="referenceH4">Auftraggeber</h4>
            <p className="referenceText">
              Dipl.-Restaurator Michael Lange. Der Auftrag erfolgte an den
              Vorbezeichneten vom Universitätsbauamt Regensburg.
            </p>

            <h4 className="referenceH4">Fachbehörden</h4>
            <p className="referenceText">
              Universitätsbauamt Regensburg und Bayerisches Landesamt für
              Denkmalpflege, Restaurator für den Fachbereich Wand Jürgen
              Pursche.
            </p>
          </div>
        </div>
      </section>
      <Footer />
    </>
  )
}
